import React from 'react';

const Navbar = ({ userInfo }) => {
    const position = sessionStorage.getItem('position');
    return (
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
            {/* Brand Logo */}
            <a href="/" className="brand-link">
                {/* <img src="dist/img/AdminLTELogo.png" alt="AdminLTE Logo" className="brand-image img-circle elevation-3" style={{ opacity: '.8' }} /> */}
                <span className="brand-text font-weight-light">John Doe Clinic</span>
                {/* <span className="brand-text font-weight-light">Tubillara Clinic</span> */}
            </a>
            {/* Sidebar */}
            <div className="sidebar os-host os-theme-light os-host-overflow os-host-overflow-y os-host-resize-disabled os-host-scrollbar-horizontal-hidden os-host-transition"><div className="os-resize-observer-host observed"><div className="os-resize-observer" style={{ left: 0, right: 'auto' }} /></div><div className="os-size-auto-observer observed" style={{ height: 'calc(100% + 1px)', float: 'left' }}><div className="os-resize-observer" /></div><div className="os-content-glue" style={{ margin: '0px -8px', width: 249, height: 528 }} /><div className="os-padding"><div className="os-viewport os-viewport-native-scrollbars-invisible" style={{ overflowY: 'scroll' }}><div className="os-content" style={{ padding: '0px 8px', height: '100%', width: '100%' }}>
                {/* Sidebar user panel (optional) */}
                <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                    <div className="image">
                        <img src={require('./istockphoto-1200712144-612x612.jpg')} className="img-circle elevation-2" alt="User Image" />
                    </div>
                    <div className="info">
                        <a className="d-block">John Doe, MD</a>
                        {/* <a className="d-block">Jan Zoilo B. Tubillara, MD</a> */}
                    </div>
                </div>
                {/* Sidebar Menu */}
                <nav className="mt-2">
                    <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                        {/* Add icons to the links using the .nav-icon class
         with font-awesome or any other icon font library */}
                        <li className="nav-item" style={{ display: "none" }}>
                            <a href="/dashboard" className="nav-link">
                                {/* <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-microsoft nav-icon" viewBox="0 0 16 16">
                                    <path d="M7.462 0H0v7.19h7.462V0zM16 0H8.538v7.19H16V0zM7.462 8.211H0V16h7.462V8.211zm8.538 0H8.538V16H16V8.211z" />
                                </svg> */}

                                <p>Dashboard</p>
                            </a>
                        </li>
                        <li className="nav-item" style={{ display: "none" }}>
                            <a href="/calendar" className="nav-link">
                                {/* <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-microsoft nav-icon" viewBox="0 0 16 16">
                                    <path d="M7.462 0H0v7.19h7.462V0zM16 0H8.538v7.19H16V0zM7.462 8.211H0V16h7.462V8.211zm8.538 0H8.538V16H16V8.211z" />
                                </svg> */}

                                <p>Calendar</p>
                            </a>
                        </li>
                        <li className="nav-item" style={{ display: "none" }}>
                            <a href="/patient" className="nav-link">
                                {/* <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-microsoft nav-icon" viewBox="0 0 16 16">
                                    <path d="M7.462 0H0v7.19h7.462V0zM16 0H8.538v7.19H16V0zM7.462 8.211H0V16h7.462V8.211zm8.538 0H8.538V16H16V8.211z" />
                                </svg> */}

                                <p>Appointment</p>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href="/patient" className="nav-link">
                                {/* <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-microsoft nav-icon" viewBox="0 0 16 16">
                                    <path d="M7.462 0H0v7.19h7.462V0zM16 0H8.538v7.19H16V0zM7.462 8.211H0V16h7.462V8.211zm8.538 0H8.538V16H16V8.211z" />
                                </svg> */}

                                <p>Patients</p>
                            </a>
                        </li>
                        {/* <li className="nav-item">
                            <a href="/transaction" className="nav-link">
                                <p>Transactions</p>
                            </a>
                        </li> */}
                        {
                            position == 'master_admin' || position == 'admin_doctor' ?
                                <li className="nav-item">
                                    <a href="/report" className="nav-link">
                                        {/* <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-microsoft nav-icon" viewBox="0 0 16 16">
                                        <path d="M7.462 0H0v7.19h7.462V0zM16 0H8.538v7.19H16V0zM7.462 8.211H0V16h7.462V8.211zm8.538 0H8.538V16H16V8.211z" />
                                    </svg> */}

                                        <p>Reports</p>
                                    </a>
                                </li>
                                : null

                        }
                    </ul>
                </nav>
                {/* /.sidebar-menu */}
            </div></div></div><div className="os-scrollbar os-scrollbar-horizontal os-scrollbar-unusable os-scrollbar-auto-hidden"><div className="os-scrollbar-track"><div className="os-scrollbar-handle" style={{ width: '100%', transform: 'translate(0px, 0px)' }} /></div></div><div className="os-scrollbar os-scrollbar-vertical os-scrollbar-auto-hidden"><div className="os-scrollbar-track"><div className="os-scrollbar-handle" style={{ height: '38.9257%', transform: 'translate(0px, 0px)' }} /></div></div><div className="os-scrollbar-corner" /></div>
            {/* /.sidebar */}
        </aside>

    );
}

export default Navbar;
