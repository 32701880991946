import React, { useContext, useEffect, useMemo, useState } from 'react';
import AppContext from '../context/AppContext';
import PrescriptionDose from '../extra/PrescriptionDose';
import PrescriptionFrequency from '../extra/PrescriptionFrequency';
import PrescriptionDuration from '../extra/PrescriptionDuration';
import { AutoComplete } from "primereact/autocomplete";
import axios from 'axios';
import ENV from '../ENV';

const CreatePrescription = ({ onclickedpatient, onConsultationButton }) => {
    
    if(localStorage.getItem('PrescriptionList')) {
        var prescriptlist = localStorage.getItem('PrescriptionList');
        // console.log(1)
    } else {
        var prescriptlist = JSON.stringify([]);
        // console.log(2)
    }
    

    const { state, dispatch } = useContext(AppContext);
    const [ suggested, setSuggessted ] = useState([]);
    const [activesearch, setActiveSearch] = useState('');
    const [value, setValue] = useState('');
    const [items, setItems] = useState([]);
    const [selecteditem, setSelectedItem] = useState(0);

    const [inputform, setInputForm] = useState({
        "patient_id": onclickedpatient.patient_id,
        "medication": "",
        "form": "",
        "strength": "",
        "dose": "",
        "frequency": "",
        "duration": "",
        "quantity": "",
        "instruction": "",
        "indication": ""
    })

    const [prescriptionlist, setPrescriptionList] = useState(JSON.parse(prescriptlist))
    
    
    const prescriptiondata = prescriptionlist?.filter((i) => {
        return i.patient_id === onclickedpatient.patient_id;
    });

    const data = prescriptionlist?.filter((i) => {
        return i.patient_id === onclickedpatient.patient_id;
    });

    const onPrescriptionBtn = async (text, item) => {
        if (text == 'add') {
            if (inputform.medication!='') {
                setPrescriptionList([...prescriptionlist, {
                    "patient_id": onclickedpatient.patient_id,
                    "medication": inputform.medication,
                    "form": inputform.form,
                    "strength":  inputform.form == "Film-coated tablet" ? `${inputform.strength} mg` : inputform.strength,
                    "dose": inputform.dose,
                    "frequency": inputform.frequency,
                    "duration": inputform.duration,
                    "quantity": inputform.quantity,
                    "instruction": inputform.instruction,
                    "indication": inputform.indication
                }])
                localStorage.setItem("PrescriptionList", JSON.stringify([...prescriptionlist, {
                    "patient_id": onclickedpatient.patient_id,
                    "medication": inputform.medication,
                    "form": inputform.form,
                    "strength": inputform.strength,
                    "dose": inputform.dose,
                    "frequency": inputform.frequency,
                    "duration": inputform.duration,
                    "quantity": inputform.quantity,
                    "instruction": inputform.instruction,
                    "indication": inputform.indication
                }]))
                setInputForm({
                    "patient_id": onclickedpatient.patient_id,
                    "medication": "",
                    "form": "",
                    "strength": "",
                    "dose": "",
                    "frequency": "",
                    "duration": "",
                    "quantity": "",
                    "instruction": "",
                    "indication": ""
                })
            }


            var formData = new FormData();
            formData.append("save_new_medication_sugg", 1);
            formData.append("medication", inputform.medication);
            await axios({
                method: "post",
                url: ENV.DOMAIN + 'process.php',
                data: formData,
            }).then(()=>{
                SuggestData()
            })
        } else if (text == 'remove') {
            const data = prescriptionlist.filter((i) => {
                return item.patient_id && i !== item;
            });
            
            if(data.length > 0) {
                setPrescriptionList(data)
                localStorage.setItem("PrescriptionList", JSON.stringify(data))
            } else {
                setPrescriptionList([])
                localStorage.setItem("PrescriptionList", JSON.stringify([]))
            }
            
        }
        
    }

    const SuggestData = async () => {
        var formData = new FormData();
        formData.append("medication_sugg_list", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            setSuggessted(res.data)
        })
    }

    useEffect(async()=> {
        SuggestData()
    }, [])

    const _data = suggested.filter(item => {
        // return item.name.toLowerCase().includes(String(inputform.medication).trim().toLowerCase())
        const searchItem = String(inputform.medication).toLowerCase()
        const name = item.name.toLowerCase()
        return searchItem && name.toLowerCase().includes(String(searchItem).trim().toLowerCase()) && name != searchItem
    }).slice(0, 10);

    const handlekey = (e) => {
        if (selecteditem < _data.length) {
            if(e.key === 'ArrowUp' && selecteditem > 0) {
                setSelectedItem(prev => prev - 1)
            } else if (e.key === 'ArrowDown' && selecteditem < _data.length - 1) {
                setSelectedItem(prev => prev + 1)
            } else if (e.key === 'Enter' && selecteditem >= 0) {
                setActiveSearch('')
                setInputForm({ ...inputform, 'medication': _data[selecteditem].name })
            }
        } else {
            setSelectedItem(-1)
        }
    }

    const keypress = (e) => {
        if (e.key === 'Enter') {
            setActiveSearch('')
            setInputForm({ ...inputform, 'medication': e.target.value })
        }
    }

    const search = (e) => {
        setActiveSearch(e.target.value)
        setInputForm({ ...inputform, [e.target.name]: e.target.value })
    }

    return (
        <>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", height: "85vh" }}>
                <div style={{ width: "50%", height: "85vh", backgroundColor: "#fff" }}>
                    <div style={{ padding: 10 }}>
                        <div style={{position:"relative"}}>
                            <div>
                                <span>Medication</span>
                            </div> 
                            {/* <AutoComplete field="name" value={inputform.medication} suggestions={suggestedList} completeMethod={search} onChange={(e) => setInputForm({ ...inputform, "medication": e.target.value })} />                            */}
                            
                            <div>
                                <input list="browsers" name="medication" id="browser" onChange={(e) => setInputForm({ ...inputform, [e.target.name]: e.target.value })} value={inputform.medication} style={{ width: "100%", height: 40, borderRadius: 5, border: "1px solid #ddd", padding: 10 }} />
                                <datalist id="browsers">
                                    {
                                        suggested.map((item, key) => {
                                            return (
                                                <option key={key} value={item.name}></option>
                                            )
                                        })
                                    }
                                </datalist>
                            </div>

                            
                            
                            
                            {/* <input onKeyDown={handlekey} onKeyPress={keypress} autoComplete="off" name='medication' value={inputform.medication} onChange={(e) => search(e)} style={{ width: "100%", height: 40, borderRadius: 5, border: "1px solid #ddd", padding: 10 }} />
                            <div style={{position:"absolute", width:"100%",boxShadow: "-1px 2px 5px grey"}}>
                            {
                                activesearch!='' ?
                                _data.map((item, key) => {
                                        return (
                                            <div style={{padding:5, border:"1px solid #ddd", backgroundColor:selecteditem == key ? "#ddd" : "#fff"}} key={key} onClick={() => setInputForm({ ...inputform, 'medication': item.name })}>{item.name}</div>
                                        )
                                    })
                                    :null
                            }
                            </div> */}
                        </div>
                        <div>
                            <div>
                                <span>Form</span>
                            </div>
                            {/* <select name='form' value={inputform.form} onChange={(e) => setInputForm({ ...inputform, name: 'form', [e.target.name]: e.target.value })} style={{ width: "100%", height: 40, borderRadius: 5, border: "1px solid #ddd", padding: 10 }}>
                                <option selected="" disabled="">Select</option>
                                <option value={"Film-coated tablet"}>
                                    Film-coated tablet
                                </option>
                                <option value={"Capsule"}>
                                    Capsule
                                </option>
                                <option value={"Oral solution"}>
                                    Oral solution
                                </option>
                            </select> */}
                            <input name='form' onChange={(e) => setInputForm({ ...inputform, [e.target.name]: e.target.value })} value={inputform.form} style={{ width: "100%", height: 40, borderRadius: 5, border: "1px solid #ddd", padding: 10 }} />
                        </div>
                        <div>
                            <div>
                                <span>Strength</span>
                            </div>
                            <input type='text' name='strength' onChange={(e) => setInputForm({ ...inputform, name: 'strength', [e.target.name]: e.target.value })} value={inputform.strength} style={{ width: "100%", height: 40, borderRadius: 5, border: "1px solid #ddd", padding: 10 }} />
                        </div>
                        <div>
                            <div>
                                <span>Dose</span>
                            </div>
                            {/* <PrescriptionDose setInputForm={setInputForm} inputform={inputform} /> */}
                            <input name='dose' onChange={(e) => setInputForm({ ...inputform, [e.target.name]: e.target.value })} value={inputform.dose} style={{ width: "100%", height: 40, borderRadius: 5, border: "1px solid #ddd", padding: 10 }} />
                        </div>
                        <div>
                            <div>
                                <span>Frequency</span>
                            </div>
                            <PrescriptionFrequency setInputForm={setInputForm} inputform={inputform} />
                        </div>
                        <div>
                            <div>
                                <span>Duration</span>
                            </div>
                            <PrescriptionDuration setInputForm={setInputForm} inputform={inputform} />
                            {/* <input name='duration' onChange={(e) => setInputForm({ ...inputform, [e.target.name]: e.target.value })} style={{ width: "100%", height: 40, borderRadius: 5, border: "1px solid #ddd", padding: 10 }} /> */}
                        </div>
                        <div>
                            <div>
                                <span>Quantity</span>
                            </div>
                            <input type='number' name='quantity' value={inputform.quantity} onChange={(e) => setInputForm({ ...inputform, name: 'quantity', [e.target.name]: e.target.value })} style={{ width: "100%", height: 40, borderRadius: 5, border: "1px solid #ddd", padding: 10 }} />
                        </div>
                        <div>
                            <div>
                                <span>Instruction</span>
                            </div>
                            <input name='instruction' value={inputform.instruction} onChange={(e) => setInputForm({ ...inputform, name: 'instruction', [e.target.name]: e.target.value })} style={{ width: "100%", height: 40, borderRadius: 5, border: "1px solid #ddd", padding: 10 }} />
                        </div>
                        {/* <div>
                            <div>
                                <span>Indication</span>
                            </div>
                            <input name='indication' onChange={(e) => setInputForm({ ...inputform, [e.target.name]: e.target.value })} style={{ width: "100%", height: 40, borderRadius: 5, border: "1px solid #ddd", padding: 10 }} />
                        </div> */}
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", paddingTop: 10, paddingBottom: 10 }}>
                            {
                                prescriptiondata.length>0 ?
                                <button onClick={() => onConsultationButton("")} style={{ backgroundColor: "red", color: "#fff", borderRadius: 5, width: "auto", height:40, border: "3px solid #fff", boxShadow: "-1px 2px 5px grey" }}>Save & Return </button>
                                :
                                <button onClick={() => onConsultationButton("")} style={{ backgroundColor: "red", color: "#fff", borderRadius: 5, width: "auto", height:40, border: "3px solid #fff", boxShadow: "-1px 2px 5px grey" }}>Back </button>
                            }
                            <button onClick={() => onPrescriptionBtn('add', null)} style={{ backgroundColor: "blue", color: "#fff", borderRadius: 5, width: 100, height:40, border: "3px solid #fff", boxShadow: "-1px 2px 5px grey" }}>Add</button>
                            {/* <button onClick={() => onPrescriptionBtn('remove')}>Add</button> */}
                        </div>
                    </div>
                </div>
                <div style={{ width: "100%", height: "85vh" }}>
                    <div style={{ padding: 5 }}>
                        <span>Prescription Information</span>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", gap: 5, padding: 5 }}>
                        {
                            prescriptiondata.map((i, k) => {
                                // return (
                                //     <div key={k} style={{ width: "100%", padding: 5, backgroundColor: "#fff", borderRadius: 5, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                //         <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                //             <div style={{ fontSize: 18, fontWeight: 600 }}>
                                //                 {i.medication}    
                                //             </div>
                                //             <>
                                //                 <div style={{ fontSize: 12, fontWeight: 600 }}>{i.strength} {i.form} #{i.quantity}</div>
                                //                 <div style={{ fontSize: 12, marginLeft: 10 }}>Sig. {i.dose}, {i.frequency} {i.duration == 'Maintenance' ? '' : ' for ' + i.duration}</div>
                                //                 <div style={{ fontSize: 12, marginLeft: 40 }}>{i.instruction}</div>
                                //             </>
                                //         </div>
                                //         <div>
                                //             <button onClick={() => onPrescriptionBtn('remove', i)}
                                //                 style={{borderRadius: 5, border: "1px solid #ddd", fontWeight: 500, backgroundColor: "#cc7171", color: "#fff"}}>Remove</button>
                                //         </div>
                                //     </div>
                                // )




                                // if(i.medication!='' && i.form!='' && i.strength!='' && i.quantity!='' && i.dose!='' && i.frequency!='' && i.duration!='' && i.instruction!=''){
                                //     return (
                                //         <div key={k} style={{ width: "100%", padding: 5, backgroundColor: "#fff", borderRadius: 5, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                //             <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                //                 <div style={{ fontSize: 18, fontWeight: 600 }}>
                                //                     {i.medication}    
                                //                 </div>
                                //                 <>
                                //                     <div style={{ fontSize: 12, fontWeight: 600 }}>{i.strength} {i.form} #{i.quantity}</div>
                                //                     <div style={{ fontSize: 12, marginLeft: 10 }}>Sig. {i.dose}, {i.frequency} {i.duration == 'Maintenance' ? '' : ' for ' + i.duration}</div>
                                //                     <div style={{ fontSize: 12, marginLeft: 40 }}>{i.instruction}</div>
                                //                 </>
                                //             </div>
                                //             <div>
                                //                 <button onClick={() => onPrescriptionBtn('remove', i)}
                                //                     style={{borderRadius: 5, border: "1px solid #ddd", fontWeight: 500, backgroundColor: "#cc7171", color: "#fff"}}>Remove</button>
                                //             </div>
                                //         </div>
                                //     )
                                // } else if(i.medication!='' && i.form!='' && i.strength=='' && i.quantity=='' && i.dose=='' && i.frequency=='' && i.duration=='' && i.instruction==''){
                                //     return (
                                //         <div key={k} style={{ width: "100%", padding: 5, backgroundColor: "#fff", borderRadius: 5, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                //             <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                //                 <div style={{ fontSize: 18, fontWeight: 600 }}>
                                //                     {i.medication}    
                                //                 </div>
                                //                 <>
                                //                     <div style={{ fontSize: 12, fontWeight: 600 }}>{i.form}</div>
                                //                 </>
                                //             </div>
                                //             <div>
                                //                 <button onClick={() => onPrescriptionBtn('remove', i)}
                                //                     style={{borderRadius: 5, border: "1px solid #ddd", fontWeight: 500, backgroundColor: "#cc7171", color: "#fff"}}>Remove</button>
                                //             </div>
                                //         </div>
                                //     )
                                // } else if(i.medication!='' && i.form=='' && i.strength=='' && i.quantity!='' && i.dose=='' && i.frequency=='' && i.duration=='' && i.instruction!=''){
                                //     return (
                                //         <div key={k} style={{ width: "100%", padding: 5, backgroundColor: "#fff", borderRadius: 5, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                //             <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                //                 <div style={{ fontSize: 18, fontWeight: 600 }}>
                                //                     {i.medication} #{i.quantity}
                                //                 </div>
                                //                 <>
                                //                     <div style={{ fontSize: 12, marginLeft: 20 }}>{i.instruction}</div>
                                //                 </>
                                //             </div>
                                //             <div>
                                //                 <button onClick={() => onPrescriptionBtn('remove', i)}
                                //                     style={{borderRadius: 5, border: "1px solid #ddd", fontWeight: 500, backgroundColor: "#cc7171", color: "#fff"}}>Remove</button>
                                //             </div>
                                //         </div>
                                //     )
                                // } 
                                // else {
                                //     return (
                                //         <div key={k} style={{ width: "100%", padding: 5, backgroundColor: "#fff", borderRadius: 5, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                //             <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                //                 <div style={{ fontSize: 18, fontWeight: 600 }}>
                                //                     {i.medication}    
                                //                 </div>
                                //                 <>
                                //                     <div style={{ fontSize: 12, fontWeight: 600 }}>{i.strength} {i.form} #{i.quantity}</div>
                                //                     <div style={{ fontSize: 12, marginLeft: 10 }}>Sig. {i.dose}, {i.frequency} {i.duration == 'Maintenance' ? '' : ' for ' + i.duration}</div>
                                //                     <div style={{ fontSize: 12, marginLeft: 40 }}>{i.instruction}</div>
                                //                 </>
                                //             </div>
                                //             <div>
                                //                 <button onClick={() => onPrescriptionBtn('remove', i)}
                                //                     style={{borderRadius: 5, border: "1px solid #ddd", fontWeight: 500, backgroundColor: "#cc7171", color: "#fff"}}>Remove</button>
                                //             </div>
                                //         </div>
                                //     )
                                // }









                                if(i.medication!='' && i.form!='' && i.strength!='' && i.quantity!='' && i.dose!='' && i.frequency!='' && i.duration!='' && i.instruction!=''){
                                    return (
                                        <div key={k} style={{ width: "100%", padding: 5, backgroundColor: "#fff", borderRadius: 5, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                                <div style={{ fontSize: 18, fontWeight: 600 }}>
                                                    {i.medication}    
                                                </div>
                                                <>
                                                    <div style={{ fontSize: 12, fontWeight: 600 }}>{i.strength} {i.form} #{i.quantity}</div>
                                                    <div style={{ fontSize: 12, marginLeft: 10 }}>Sig. {i.dose}, {i.frequency} {i.duration == 'Maintenance' ? '' : ' for ' + i.duration}</div>
                                                    <div style={{ fontSize: 12, marginLeft: 40 }}>{i.instruction}</div>
                                                </>
                                            </div>
                                            <div>
                                                <button onClick={() => onPrescriptionBtn('remove', i)}
                                                    style={{borderRadius: 5, border: "1px solid #ddd", fontWeight: 500, backgroundColor: "#cc7171", color: "#fff"}}>Remove</button>
                                            </div>
                                        </div>
                                    )
                                } else if(i.medication!='' && i.form!='' && i.strength=='' && i.quantity=='' && i.dose=='' && i.frequency=='' && i.duration=='' && i.instruction==''){
                                    return (
                                        <div key={k} style={{ width: "100%", padding: 5, backgroundColor: "#fff", borderRadius: 5, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                                <div style={{ fontSize: 18, fontWeight: 600 }}>
                                                    {i.medication}    
                                                </div>
                                                <>
                                                    <div style={{ fontSize: 12, fontWeight: 600 }}>{i.form}</div>
                                                </>
                                            </div>
                                            <div>
                                                <button onClick={() => onPrescriptionBtn('remove', i)}
                                                    style={{borderRadius: 5, border: "1px solid #ddd", fontWeight: 500, backgroundColor: "#cc7171", color: "#fff"}}>Remove</button>
                                            </div>
                                        </div>
                                    )
                                } else if(i.medication!='' && i.form!='' && i.strength!='' && i.quantity!='' && i.dose!='' && i.frequency!='' && i.duration!='' && i.instruction==''){
                                    return (
                                        <div key={k} style={{ width: "100%", padding: 5, backgroundColor: "#fff", borderRadius: 5, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                                <div style={{ fontSize: 18, fontWeight: 600 }}>
                                                    {i.medication}    
                                                </div>
                                                <>
                                                    <div style={{ fontSize: 12, fontWeight: 600 }}>{i.strength} {i.form} #{i.quantity}</div>
                                                    <div style={{ fontSize: 12, marginLeft: 10 }}>Sig. {i.dose}, {i.frequency} {i.duration == 'Maintenance' ? '' : ' for ' + i.duration}</div>
                                                </>
                                            </div>
                                            <div>
                                                <button onClick={() => onPrescriptionBtn('remove', i)}
                                                    style={{borderRadius: 5, border: "1px solid #ddd", fontWeight: 500, backgroundColor: "#cc7171", color: "#fff"}}>Remove</button>
                                            </div>
                                        </div>
                                    )
                                } else if(i.medication!='' && i.form!='' && i.strength!='' && i.quantity=='' && i.dose=='' && i.frequency=='' && i.duration=='' && i.instruction==''){
                                    return (
                                        <div key={k} style={{ width: "100%", padding: 5, backgroundColor: "#fff", borderRadius: 5, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                                <div style={{ fontSize: 18, fontWeight: 600 }}>
                                                    {i.medication}    
                                                </div>
                                                <>
                                                    <div style={{ fontSize: 12, fontWeight: 600 }}>{i.strength} {i.form}</div>
                                                </>
                                            </div>
                                            <div>
                                                <button onClick={() => onPrescriptionBtn('remove', i)}
                                                    style={{borderRadius: 5, border: "1px solid #ddd", fontWeight: 500, backgroundColor: "#cc7171", color: "#fff"}}>Remove</button>
                                            </div>
                                        </div>
                                    )
                                } else if(i.medication!='' && i.form!='' && i.strength!='' && i.quantity!='' && i.dose=='' && i.frequency!='' && i.duration!='' && i.instruction!=''){
                                    return (
                                        <div key={k} style={{ width: "100%", padding: 5, backgroundColor: "#fff", borderRadius: 5, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                                <div style={{ fontSize: 18, fontWeight: 600 }}>
                                                    {i.medication}    
                                                </div>
                                                <>
                                                    <div style={{ fontSize: 12, fontWeight: 600 }}>{i.strength} {i.form} #{i.quantity}</div>
                                                    <div style={{ fontSize: 12, marginLeft: 10 }}>Sig. {i.frequency} {i.duration == 'Maintenance' ? '' : ' for ' + i.duration}</div>
                                                    <div style={{ fontSize: 12, marginLeft: 40 }}>{i.instruction}</div>
                                                </>
                                            </div>
                                            <div>
                                                <button onClick={() => onPrescriptionBtn('remove', i)}
                                                    style={{borderRadius: 5, border: "1px solid #ddd", fontWeight: 500, backgroundColor: "#cc7171", color: "#fff"}}>Remove</button>
                                            </div>
                                        </div>
                                    )
                                } else if(i.medication!='' && i.form!='' && i.strength!='' && i.quantity!='' && i.dose!='' && i.frequency=='' && i.duration=='' && i.instruction==''){
                                    return (
                                        <div key={k} style={{ width: "100%", padding: 5, backgroundColor: "#fff", borderRadius: 5, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                                <div style={{ fontSize: 18, fontWeight: 600 }}>
                                                    {i.medication}    
                                                </div>
                                                <>
                                                    <div style={{ fontSize: 12, fontWeight: 600 }}>{i.strength} {i.form} #{i.quantity}</div>
                                                    <div style={{ fontSize: 12, marginLeft: 10 }}>Sig. {i.dose}</div>
                                                </>
                                            </div>
                                            <div>
                                                <button onClick={() => onPrescriptionBtn('remove', i)}
                                                    style={{borderRadius: 5, border: "1px solid #ddd", fontWeight: 500, backgroundColor: "#cc7171", color: "#fff"}}>Remove</button>
                                            </div>
                                        </div>
                                    )
                                } else if(i.medication!='' && i.form=='' && i.strength=='' && i.quantity!='' && i.dose=='' && i.frequency!='' && i.duration!='' && i.instruction==''){
                                    return (
                                        <div key={k} style={{ width: "100%", padding: 5, backgroundColor: "#fff", borderRadius: 5, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                                <div style={{ fontSize: 18, fontWeight: 600 }}>
                                                    {i.medication}
                                                </div>
                                                <>
                                                    <div style={{ fontSize: 12, fontWeight: 600 }}>#{i.quantity}</div>
                                                    <div style={{ fontSize: 12, marginLeft: 10 }}>Sig. {i.frequency} {i.duration == 'Maintenance' ? '' : ' for ' + i.duration}</div>
                                                </>
                                            </div>
                                            <div>
                                                <button onClick={() => onPrescriptionBtn('remove', i)}
                                                    style={{borderRadius: 5, border: "1px solid #ddd", fontWeight: 500, backgroundColor: "#cc7171", color: "#fff"}}>Remove</button>
                                            </div>
                                        </div>
                                    )
                                } else if(i.medication!='' && i.form!='' && i.strength!='' && i.quantity=='' && i.dose!='' && i.frequency=='' && i.duration=='' && i.instruction==''){
                                    return (
                                        <div key={k} style={{ width: "100%", padding: 5, backgroundColor: "#fff", borderRadius: 5, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                                <div style={{ fontSize: 18, fontWeight: 600 }}>
                                                    {i.medication}
                                                </div>
                                                <>
                                                    <div style={{ fontSize: 12, fontWeight: 600 }}>{i.strength} {i.form}</div>
                                                    <div style={{ fontSize: 12, marginLeft: 10 }}>Sig. {i.dose}</div>
                                                </>
                                            </div>
                                            <div>
                                                <button onClick={() => onPrescriptionBtn('remove', i)}
                                                    style={{borderRadius: 5, border: "1px solid #ddd", fontWeight: 500, backgroundColor: "#cc7171", color: "#fff"}}>Remove</button>
                                            </div>
                                        </div>
                                    )
                                } else if(i.medication!='' && i.form!='' && i.strength!='' && i.quantity!='' && i.dose!='' && i.frequency!='' && i.duration=='' && i.instruction!=''){
                                    return (
                                        <div key={k} style={{ width: "100%", padding: 5, backgroundColor: "#fff", borderRadius: 5, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                                <div style={{ fontSize: 18, fontWeight: 600 }}>
                                                    {i.medication}
                                                </div>
                                                <>
                                                    <div style={{ fontSize: 12, fontWeight: 600 }}>{i.strength} {i.form} #{i.quantity}</div>
                                                    <div style={{ fontSize: 12, marginLeft: 10 }}>Sig. {i.dose}, {i.frequency}</div>
                                                    <div style={{ fontSize: 12, marginLeft: 40 }}>{i.instruction}</div>
                                                </>
                                            </div>
                                            <div>
                                                <button onClick={() => onPrescriptionBtn('remove', i)}
                                                    style={{borderRadius: 5, border: "1px solid #ddd", fontWeight: 500, backgroundColor: "#cc7171", color: "#fff"}}>Remove</button>
                                            </div>
                                        </div>
                                    )
                                } else if(i.medication!='' && i.form!='' && i.strength!='' && i.quantity!='' && i.dose!='' && i.frequency=='' && i.duration!='' && i.instruction!=''){
                                    return (
                                        <div key={k} style={{ width: "100%", padding: 5, backgroundColor: "#fff", borderRadius: 5, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                                <div style={{ fontSize: 18, fontWeight: 600 }}>
                                                    {i.medication}
                                                </div>
                                                <>
                                                    <div style={{ fontSize: 12, fontWeight: 600 }}>{i.strength} {i.form} #{i.quantity}</div>
                                                    <div style={{ fontSize: 12, marginLeft: 10 }}>Sig. {i.dose}, {i.duration == 'Maintenance' ? '' : ' for ' + i.duration}</div>
                                                    <div style={{ fontSize: 12, marginLeft: 40 }}>{i.instruction}</div>
                                                </>
                                            </div>
                                            <div>
                                                <button onClick={() => onPrescriptionBtn('remove', i)}
                                                    style={{borderRadius: 5, border: "1px solid #ddd", fontWeight: 500, backgroundColor: "#cc7171", color: "#fff"}}>Remove</button>
                                            </div>
                                        </div>
                                    )
                                } else if(i.medication!='' && i.form=='' && i.strength!='' && i.quantity!='' && i.dose!='' && i.frequency=='' && i.duration=='' && i.instruction!=''){
                                    return (
                                        <div key={k} style={{ width: "100%", padding: 5, backgroundColor: "#fff", borderRadius: 5, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                                <div style={{ fontSize: 18, fontWeight: 600 }}>
                                                    {i.medication}
                                                </div>
                                                <>
                                                    <div style={{ fontSize: 12, fontWeight: 600 }}>{i.strength} #{i.quantity}</div>
                                                    <div style={{ fontSize: 12, marginLeft: 10 }}>Sig. {i.dose}</div>
                                                    <div style={{ fontSize: 12, marginLeft: 40 }}>{i.instruction}</div>
                                                </>
                                            </div>
                                            <div>
                                                <button onClick={() => onPrescriptionBtn('remove', i)}
                                                    style={{borderRadius: 5, border: "1px solid #ddd", fontWeight: 500, backgroundColor: "#cc7171", color: "#fff"}}>Remove</button>
                                            </div>
                                        </div>
                                    )
                                } else if(i.medication!='' && i.form!='' && i.strength=='' && i.quantity!='' && i.dose!='' && i.frequency=='' && i.duration!='' && i.instruction!=''){
                                    return (
                                        <div key={k} style={{ width: "100%", padding: 5, backgroundColor: "#fff", borderRadius: 5, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                                <div style={{ fontSize: 18, fontWeight: 600 }}>
                                                    {i.medication}
                                                </div>
                                                <>
                                                    <div style={{ fontSize: 12, fontWeight: 600 }}>{i.form} #{i.quantity}</div>
                                                    <div style={{ fontSize: 12, marginLeft: 10 }}>Sig. {i.dose}, {i.frequency} {i.duration == 'Maintenance' ? '' : ' for ' + i.duration}</div>
                                                    <div style={{ fontSize: 12, marginLeft: 40 }}>{i.instruction}</div>
                                                </>
                                            </div>
                                            <div>
                                                <button onClick={() => onPrescriptionBtn('remove', i)}
                                                    style={{borderRadius: 5, border: "1px solid #ddd", fontWeight: 500, backgroundColor: "#cc7171", color: "#fff"}}>Remove</button>
                                            </div>
                                        </div>
                                    )
                                } else if(i.medication!='' && i.form!='' && i.strength=='' && i.quantity=='' && i.dose!='' && i.frequency=='' && i.duration=='' && i.instruction!=''){
                                    return (
                                        <div key={k} style={{ width: "100%", padding: 5, backgroundColor: "#fff", borderRadius: 5, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                                <div style={{ fontSize: 18, fontWeight: 600 }}>
                                                    {i.medication}
                                                </div>
                                                <>
                                                    <div style={{ fontSize: 12, fontWeight: 600 }}>{i.form}</div>
                                                    <div style={{ fontSize: 12, marginLeft: 10 }}>Sig. {i.dose}</div>
                                                    <div style={{ fontSize: 12, marginLeft: 40 }}>{i.instruction}</div>
                                                </>
                                            </div>
                                            <div>
                                                <button onClick={() => onPrescriptionBtn('remove', i)}
                                                    style={{borderRadius: 5, border: "1px solid #ddd", fontWeight: 500, backgroundColor: "#cc7171", color: "#fff"}}>Remove</button>
                                            </div>
                                        </div>
                                    )
                                } else if(i.medication!='' && i.form=='' && i.strength!='' && i.quantity=='' && i.dose=='' && i.frequency=='' && i.duration=='' && i.instruction==''){
                                    return (
                                        <div key={k} style={{ width: "100%", padding: 5, backgroundColor: "#fff", borderRadius: 5, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                                <div style={{ fontSize: 18, fontWeight: 600 }}>
                                                    {i.medication}    
                                                </div>
                                                <>
                                                    <div style={{ fontSize: 12, fontWeight: 600 }}>{i.strength}</div>
                                                </>
                                            </div>
                                            <div>
                                                <button onClick={() => onPrescriptionBtn('remove', i)}
                                                    style={{borderRadius: 5, border: "1px solid #ddd", fontWeight: 500, backgroundColor: "#cc7171", color: "#fff"}}>Remove</button>
                                            </div>
                                        </div>
                                    )
                                } else if(i.medication!='' && i.form!='' && i.strength!='' && i.quantity!='' && i.dose!='' && i.frequency=='' && i.duration=='' && i.instruction!=''){
                                    return (
                                        <div key={k} style={{ width: "100%", padding: 5, backgroundColor: "#fff", borderRadius: 5, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                                <div style={{ fontSize: 18, fontWeight: 600 }}>
                                                    {i.medication}    
                                                </div>
                                                <>
                                                    <div style={{ fontSize: 12, fontWeight: 600 }}>{i.strength} {i.form} #{i.quantity}</div>
                                                    <div style={{ fontSize: 12, marginLeft: 10 }}>Sig. {i.dose}</div>
                                                    <div style={{ fontSize: 12, marginLeft: 40 }}>{i.instruction}</div>
                                                </>
                                            </div>
                                            <div>
                                                <button onClick={() => onPrescriptionBtn('remove', i)}
                                                    style={{borderRadius: 5, border: "1px solid #ddd", fontWeight: 500, backgroundColor: "#cc7171", color: "#fff"}}>Remove</button>
                                            </div>
                                        </div>
                                    )
                                } else if(i.medication!='' && i.form!='' && i.strength=='' && i.quantity!='' && i.dose!='' && i.frequency!='' && i.duration!='' && i.instruction!=''){
                                    return (
                                        <div key={k} style={{ width: "100%", padding: 5, backgroundColor: "#fff", borderRadius: 5, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                                <div style={{ fontSize: 18, fontWeight: 600 }}>
                                                    {i.medication}    
                                                </div>
                                                <>
                                                    <div style={{ fontSize: 12, fontWeight: 600 }}>{i.form} #{i.quantity}</div>
                                                    <div style={{ fontSize: 12, marginLeft: 10 }}>Sig. {i.dose}, {i.frequency} {i.duration == 'Maintenance' ? '' : ' for ' + i.duration}</div>
                                                    <div style={{ fontSize: 12, marginLeft: 40 }}>{i.instruction}</div>
                                                </>
                                            </div>
                                            <div>
                                                <button onClick={() => onPrescriptionBtn('remove', i)}
                                                    style={{borderRadius: 5, border: "1px solid #ddd", fontWeight: 500, backgroundColor: "#cc7171", color: "#fff"}}>Remove</button>
                                            </div>
                                        </div>
                                    )
                                } else if(i.medication!='' && i.form=='' && i.strength=='' && i.quantity!='' && i.dose!='' && i.frequency!='' && i.duration!='' && i.instruction!=''){
                                    return (
                                        <div key={k} style={{ width: "100%", padding: 5, backgroundColor: "#fff", borderRadius: 5, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                                <div style={{ fontSize: 18, fontWeight: 600 }}>
                                                    {i.medication}
                                                </div>
                                                <>
                                                    <div style={{ fontSize: 12, fontWeight: 600 }}>#{i.quantity}</div>
                                                    <div style={{ fontSize: 12, marginLeft: 10 }}>Sig. {i.dose}, {i.frequency} {i.duration == 'Maintenance' ? '' : ' for ' + i.duration}</div>
                                                    <div style={{ fontSize: 12, marginLeft: 40 }}>{i.instruction}</div>
                                                </>
                                            </div>
                                            <div>
                                                <button onClick={() => onPrescriptionBtn('remove', i)}
                                                    style={{borderRadius: 5, border: "1px solid #ddd", fontWeight: 500, backgroundColor: "#cc7171", color: "#fff"}}>Remove</button>
                                            </div>
                                        </div>
                                    )
                                } else if(i.medication!='' && i.form=='' && i.strength=='' && i.quantity!='' && i.dose=='' && i.frequency!='' && i.duration!='' && i.instruction!=''){
                                    return (
                                        <div key={k} style={{ width: "100%", padding: 5, backgroundColor: "#fff", borderRadius: 5, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                                <div style={{ fontSize: 18, fontWeight: 600 }}>
                                                    {i.medication}
                                                </div>
                                                <>
                                                    <div style={{ fontSize: 12, fontWeight: 600 }}>#{i.quantity}</div>
                                                    <div style={{ fontSize: 12, marginLeft: 10 }}>Sig. {i.frequency} {i.duration == 'Maintenance' ? '' : ' for ' + i.duration}</div>
                                                    <div style={{ fontSize: 12, marginLeft: 40 }}>{i.instruction}</div>
                                                </>
                                            </div>
                                            <div>
                                                <button onClick={() => onPrescriptionBtn('remove', i)}
                                                    style={{borderRadius: 5, border: "1px solid #ddd", fontWeight: 500, backgroundColor: "#cc7171", color: "#fff"}}>Remove</button>
                                            </div>
                                        </div>
                                    )
                                } else if(i.medication!='' && i.form!='' && i.strength=='' && i.quantity!='' && i.dose=='' && i.frequency!='' && i.duration!='' && i.instruction!=''){
                                    return (
                                        <div key={k} style={{ width: "100%", padding: 5, backgroundColor: "#fff", borderRadius: 5, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                                <div style={{ fontSize: 18, fontWeight: 600 }}>
                                                    {i.medication}
                                                </div>
                                                <>
                                                    <div style={{ fontSize: 12, marginLeft: 0, fontWeight: 600 }}>{i.form} #{i.quantity}</div>
                                                    <div style={{ fontSize: 12, marginLeft: 10 }}>Sig. {i.frequency} {i.duration == 'Maintenance' ? '' : ' for ' + i.duration}</div>
                                                    <div style={{ fontSize: 12, marginLeft: 40 }}>{i.instruction}</div>
                                                </>
                                            </div>
                                            <div>
                                                <button onClick={() => onPrescriptionBtn('remove', i)}
                                                    style={{borderRadius: 5, border: "1px solid #ddd", fontWeight: 500, backgroundColor: "#cc7171", color: "#fff"}}>Remove</button>
                                            </div>
                                        </div>
                                    )
                                } else if(i.medication!='' && i.form!='' && i.strength=='' && i.quantity!='' && i.dose=='' && i.frequency=='' && i.duration=='' && i.instruction!=''){
                                    return (
                                        <div key={k} style={{ width: "100%", padding: 5, backgroundColor: "#fff", borderRadius: 5, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                                <div style={{ fontSize: 18, fontWeight: 600 }}>
                                                    {i.medication}
                                                </div>
                                                <>
                                                    <div style={{ fontSize: 12, marginLeft: 0, fontWeight: 600 }}>{i.form} #{i.quantity}</div>
                                                    <div style={{ fontSize: 12, marginLeft: 20 }}>{i.instruction}</div>
                                                </>
                                            </div>
                                            <div>
                                                <button onClick={() => onPrescriptionBtn('remove', i)}
                                                    style={{borderRadius: 5, border: "1px solid #ddd", fontWeight: 500, backgroundColor: "#cc7171", color: "#fff"}}>Remove</button>
                                            </div>
                                        </div>
                                    )
                                } else if(i.medication!='' && i.form=='' && i.strength=='' && i.quantity!='' && i.dose=='' && i.frequency=='' && i.duration=='' && i.instruction!=''){
                                    return (
                                        <div key={k} style={{ width: "100%", padding: 5, backgroundColor: "#fff", borderRadius: 5, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                                <div style={{ fontSize: 18, fontWeight: 600 }}>
                                                    {i.medication}
                                                </div>
                                                <>
                                                    <div style={{ fontSize: 12, marginLeft: 10, fontWeight: 600 }}>{i.form} #{i.quantity}</div>
                                                    <div style={{ fontSize: 12, marginLeft: 20 }}>{i.instruction}</div>
                                                </>
                                            </div>
                                            <div>
                                                <button onClick={() => onPrescriptionBtn('remove', i)}
                                                    style={{borderRadius: 5, border: "1px solid #ddd", fontWeight: 500, backgroundColor: "#cc7171", color: "#fff"}}>Remove</button>
                                            </div>
                                        </div>
                                    )
                                } else if(i.medication!='' && i.form=='' && i.strength=='' && i.quantity=='' && i.dose=='' && i.frequency=='' && i.duration=='' && i.instruction!=''){
                                    return (
                                        <div key={k} style={{ width: "100%", padding: 5, backgroundColor: "#fff", borderRadius: 5, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                                <div style={{ fontSize: 18, fontWeight: 600 }}>
                                                    {i.medication}    
                                                </div>
                                                <>
                                                    <div style={{ fontSize: 12, marginLeft: 20 }}>{i.instruction}</div>
                                                </>
                                            </div>
                                            <div>
                                                <button onClick={() => onPrescriptionBtn('remove', i)}
                                                    style={{borderRadius: 5, border: "1px solid #ddd", fontWeight: 500, backgroundColor: "#cc7171", color: "#fff"}}>Remove</button>
                                            </div>
                                        </div>
                                    )
                                } else if(i.medication!='' && i.form!='' && i.strength=='' && i.quantity=='' && i.dose=='' && i.frequency!='' && i.duration!='' && i.instruction==''){
                                    return (
                                        <div key={k} style={{ width: "100%", padding: 5, backgroundColor: "#fff", borderRadius: 5, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                                <div style={{ fontSize: 18, fontWeight: 600 }}>
                                                    {i.medication}    
                                                </div>
                                                <>
                                                    <div style={{ fontSize: 12, fontWeight: 600 }}>{i.form}</div>
                                                    <div style={{ fontSize: 12, marginLeft: 10 }}>Sig. {i.dose}, {i.frequency} {i.duration == 'Maintenance' ? '' : ' for ' + i.duration}</div>
                                                </>
                                            </div>
                                            <div>
                                                <button onClick={() => onPrescriptionBtn('remove', i)}
                                                    style={{borderRadius: 5, border: "1px solid #ddd", fontWeight: 500, backgroundColor: "#cc7171", color: "#fff"}}>Remove</button>
                                            </div>
                                        </div>
                                    )
                                } else if(i.medication!='' && i.form=='' && i.strength!='' && i.quantity!='' && i.dose!='' && i.frequency!='' && i.duration!='' && i.instruction!=''){
                                    return (
                                        <div key={k} style={{ width: "100%", padding: 5, backgroundColor: "#fff", borderRadius: 5, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                                <div style={{ fontSize: 18, fontWeight: 600 }}>
                                                    {i.medication}    
                                                </div>
                                                <>
                                                    <div style={{ fontSize: 12, fontWeight: 600 }}>{i.strength} #{i.quantity}</div>
                                                    <div style={{ fontSize: 12, marginLeft: 10 }}>Sig. {i.dose}, {i.frequency} {i.duration == 'Maintenance' ? '' : ' for ' + i.duration}</div>
                                                    <div style={{ fontSize: 12, marginLeft: 40 }}>{i.instruction}</div>
                                                </>
                                            </div>
                                            <div>
                                                <button onClick={() => onPrescriptionBtn('remove', i)}
                                                    style={{borderRadius: 5, border: "1px solid #ddd", fontWeight: 500, backgroundColor: "#cc7171", color: "#fff"}}>Remove</button>
                                            </div>
                                        </div>
                                    )
                                } else if(i.medication!='' && i.form!='' && i.strength=='' && i.quantity=='' && i.dose=='' && i.frequency=='' && i.duration=='' && i.instruction!=''){
                                    return (
                                        <div key={k} style={{ width: "100%", padding: 5, backgroundColor: "#fff", borderRadius: 5, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                                <div style={{ fontSize: 18, fontWeight: 600 }}>
                                                    {i.medication}    
                                                </div>
                                                <>
                                                    <div style={{ fontSize: 12, marginLeft: 0, fontWeight: 600 }}>{i.form}</div>
                                                    <div style={{ fontSize: 12, marginLeft: 20 }}>{i.instruction}</div>
                                                </>
                                            </div>
                                            <div>
                                                <button onClick={() => onPrescriptionBtn('remove', i)}
                                                    style={{borderRadius: 5, border: "1px solid #ddd", fontWeight: 500, backgroundColor: "#cc7171", color: "#fff"}}>Remove</button>
                                            </div>
                                        </div>
                                    )
                                } else if(i.medication!='' && i.form=='' && i.strength=='' && i.quantity=='' && i.dose!='' && i.frequency=='' && i.duration=='' && i.instruction!=''){
                                    return (
                                        <div key={k} style={{ width: "100%", padding: 5, backgroundColor: "#fff", borderRadius: 5, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                                <div style={{ fontSize: 18, fontWeight: 600 }}>
                                                    {i.medication}    
                                                </div>
                                                <>
                                                    <div style={{ fontSize: 12, marginLeft: 10 }}>Sig. {i.dose}</div>
                                                    <div style={{ fontSize: 12, marginLeft: 40 }}>{i.instruction}</div>
                                                </>
                                            </div>
                                            <div>
                                                <button onClick={() => onPrescriptionBtn('remove', i)}
                                                    style={{borderRadius: 5, border: "1px solid #ddd", fontWeight: 500, backgroundColor: "#cc7171", color: "#fff"}}>Remove</button>
                                            </div>
                                        </div>
                                    )
                                } else if(i.medication!='' && i.form=='' && i.strength=='' && i.quantity!='' && i.dose!='' && i.frequency=='' && i.duration=='' && i.instruction!=''){
                                    return (
                                        <div key={k} style={{ width: "100%", padding: 5, backgroundColor: "#fff", borderRadius: 5, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                                <div style={{ fontSize: 18, fontWeight: 600 }}>
                                                    {i.medication}  
                                                </div>
                                                <>
                                                    <div style={{ fontSize: 12, fontWeight: 600 }}>#{i.quantity}</div>
                                                    <div style={{ fontSize: 12, marginLeft: 10 }}>Sig. {i.dose}</div>
                                                    <div style={{ fontSize: 12, marginLeft: 40 }}>{i.instruction}</div>
                                                </>
                                            </div>
                                            <div>
                                                <button onClick={() => onPrescriptionBtn('remove', i)}
                                                    style={{borderRadius: 5, border: "1px solid #ddd", fontWeight: 500, backgroundColor: "#cc7171", color: "#fff"}}>Remove</button>
                                            </div>
                                        </div>
                                    )
                                } else if(i.medication!='' && i.form=='' && i.strength=='' && i.quantity=='' && i.dose=='' && i.frequency=='' && i.duration=='' && i.instruction==''){
                                    return (
                                        <div key={k} style={{ width: "100%", padding: 5, backgroundColor: "#fff", borderRadius: 5, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                                <div style={{ fontSize: 18, fontWeight: 600 }}>
                                                    {i.medication}    
                                                </div>
                                            </div>
                                            <div>
                                                <button onClick={() => onPrescriptionBtn('remove', i)}
                                                    style={{borderRadius: 5, border: "1px solid #ddd", fontWeight: 500, backgroundColor: "#cc7171", color: "#fff"}}>Remove</button>
                                            </div>
                                        </div>
                                    )
                                } else if(i.medication!='' && i.form=='' && i.strength=='' && i.quantity=='' && i.dose!='' && i.frequency=='' && i.duration=='' && i.instruction==''){
                                    return (
                                        <div key={k} style={{ width: "100%", padding: 5, backgroundColor: "#fff", borderRadius: 5, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                                <div style={{ fontSize: 18, fontWeight: 600 }}>
                                                    {i.medication}    
                                                </div>
                                                <>
                                                    <div style={{ fontSize: 12, marginLeft: 10 }}>Sig. {i.dose}</div>
                                                </>
                                            </div>
                                            <div>
                                                <button onClick={() => onPrescriptionBtn('remove', i)}
                                                    style={{borderRadius: 5, border: "1px solid #ddd", fontWeight: 500, backgroundColor: "#cc7171", color: "#fff"}}>Remove</button>
                                            </div>
                                        </div>
                                    )
                                } else if(i.medication!='' && i.form=='' && i.strength=='' && i.quantity=='' && i.dose!='' && i.frequency!='' && i.duration!='' && i.instruction==''){
                                    return (
                                        <div key={k} style={{ width: "100%", padding: 5, backgroundColor: "#fff", borderRadius: 5, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                                <div style={{ fontSize: 18, fontWeight: 600 }}>
                                                    {i.medication}    
                                                </div>
                                                <>
                                                    <div style={{ fontSize: 12, marginLeft: 10 }}>Sig. {i.dose}, {i.frequency} {i.duration == 'Maintenance' ? '' : ' for ' + i.duration}</div>
                                                </>
                                            </div>
                                            <div>
                                                <button onClick={() => onPrescriptionBtn('remove', i)}
                                                    style={{borderRadius: 5, border: "1px solid #ddd", fontWeight: 500, backgroundColor: "#cc7171", color: "#fff"}}>Remove</button>
                                            </div>
                                        </div>
                                    )
                                } else if(i.medication!='' && i.form=='' && i.strength!='' && i.quantity=='' && i.dose!='' && i.frequency=='' && i.duration=='' && i.instruction==''){
                                    return (
                                        <div key={k} style={{ width: "100%", padding: 5, backgroundColor: "#fff", borderRadius: 5, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                                <div style={{ fontSize: 18, fontWeight: 600 }}>
                                                    {i.medication}    
                                                </div>
                                                <>
                                                    <div style={{ fontSize: 12, marginLeft: 10 }}>Sig. {i.dose}</div>
                                                </>
                                            </div>
                                            <div>
                                                <button onClick={() => onPrescriptionBtn('remove', i)}
                                                    style={{borderRadius: 5, border: "1px solid #ddd", fontWeight: 500, backgroundColor: "#cc7171", color: "#fff"}}>Remove</button>
                                            </div>
                                        </div>
                                    )
                                } else if(i.medication!='' && i.form=='' && i.strength=='' && i.quantity=='' && i.dose=='' && i.frequency!='' && i.duration!='' && i.instruction==''){
                                    return (
                                        <div key={k} style={{ width: "100%", padding: 5, backgroundColor: "#fff", borderRadius: 5, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                                <div style={{ fontSize: 18, fontWeight: 600 }}>
                                                    {i.medication}    
                                                </div>
                                                <>
                                                    <div style={{ fontSize: 12, marginLeft: 10 }}>Sig. {i.frequency} {i.duration == 'Maintenance' ? '' : ' for ' + i.duration}</div>
                                                </>
                                            </div>
                                            <div>
                                                <button onClick={() => onPrescriptionBtn('remove', i)}
                                                    style={{borderRadius: 5, border: "1px solid #ddd", fontWeight: 500, backgroundColor: "#cc7171", color: "#fff"}}>Remove</button>
                                            </div>
                                        </div>
                                    )
                                } else if(i.medication!='' && i.form=='' && i.strength!='' && i.quantity!='' && i.dose=='' && i.frequency!='' && i.duration!='' && i.instruction!=''){
                                    return (
                                        <div key={k} style={{ width: "100%", padding: 5, backgroundColor: "#fff", borderRadius: 5, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                                <div style={{ fontSize: 18, fontWeight: 600 }}>
                                                    {i.medication}    
                                                </div>
                                                <>
                                                    <div style={{ fontSize: 12, fontWeight: 600 }}>{i.strength} #{i.quantity}</div>
                                                    <div style={{ fontSize: 12, marginLeft: 10 }}>Sig. {i.frequency} {i.duration == 'Maintenance' ? '' : ' for ' + i.duration}</div>
                                                    <div style={{ fontSize: 12, marginLeft: 40 }}>{i.instruction}</div>
                                                </>
                                            </div>
                                            <div>
                                                <button onClick={() => onPrescriptionBtn('remove', i)}
                                                    style={{borderRadius: 5, border: "1px solid #ddd", fontWeight: 500, backgroundColor: "#cc7171", color: "#fff"}}>Remove</button>
                                            </div>
                                        </div>
                                    )
                                } else if(i.medication!='' && i.form!='' && i.strength!='' && i.quantity=='' && i.dose!='' && i.frequency!='' && i.duration!='' && i.instruction!=''){
                                    return (
                                        <div key={k} style={{ width: "100%", padding: 5, backgroundColor: "#fff", borderRadius: 5, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                                <div style={{ fontSize: 18, fontWeight: 600 }}>
                                                    {i.medication}    
                                                </div>
                                                <>
                                                    <div style={{ fontSize: 12, fontWeight: 600 }}>{i.strength} {i.form}</div>
                                                    <div style={{ fontSize: 12, marginLeft: 10 }}>Sig. {i.dose}, {i.frequency} {i.duration == 'Maintenance' ? '' : ' for ' + i.duration}</div>
                                                    <div style={{ fontSize: 12, marginLeft: 40 }}>{i.instruction}</div>
                                                </>
                                            </div>
                                            <div>
                                                <button onClick={() => onPrescriptionBtn('remove', i)}
                                                    style={{borderRadius: 5, border: "1px solid #ddd", fontWeight: 500, backgroundColor: "#cc7171", color: "#fff"}}>Remove</button>
                                            </div>
                                        </div>
                                    )
                                } else if(i.medication!='' && i.form!='' && i.strength!='' && i.quantity!='' && i.dose=='' && i.frequency=='' && i.duration!='' && i.instruction!=''){
                                    return (
                                        <div key={k} style={{ width: "100%", padding: 5, backgroundColor: "#fff", borderRadius: 5, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                                <div style={{ fontSize: 18, fontWeight: 600 }}>
                                                    {i.medication}    
                                                </div>
                                                <>
                                                    <div style={{ fontSize: 12, fontWeight: 600 }}>{i.strength} {i.form} #{i.quantity}</div>
                                                    <div style={{ fontSize: 12, marginLeft: 10 }}>Sig. {i.duration == 'Maintenance' ? '' : ' for ' + i.duration}</div>
                                                    <div style={{ fontSize: 12, marginLeft: 40 }}>{i.instruction}</div>
                                                </>
                                            </div>
                                            <div>
                                                <button onClick={() => onPrescriptionBtn('remove', i)}
                                                    style={{borderRadius: 5, border: "1px solid #ddd", fontWeight: 500, backgroundColor: "#cc7171", color: "#fff"}}>Remove</button>
                                            </div>
                                        </div>
                                    )
                                } else if(i.medication!='' && i.form!='' && i.strength!='' && i.quantity=='' && i.dose!='' && i.frequency!='' && i.duration=='' && i.instruction!=''){
                                    return (
                                        <div key={k} style={{ width: "100%", padding: 5, backgroundColor: "#fff", borderRadius: 5, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                                <div style={{ fontSize: 18, fontWeight: 600 }}>
                                                    {i.medication}    
                                                </div>
                                                <>
                                                    <div style={{ fontSize: 12, fontWeight: 600 }}>{i.strength} {i.form}</div>
                                                    <div style={{ fontSize: 12, marginLeft: 10 }}>Sig. {i.dose}, {i.frequency}</div>
                                                    <div style={{ fontSize: 12, marginLeft: 40 }}>{i.instruction}</div>
                                                </>
                                            </div>
                                            <div>
                                                <button onClick={() => onPrescriptionBtn('remove', i)}
                                                    style={{borderRadius: 5, border: "1px solid #ddd", fontWeight: 500, backgroundColor: "#cc7171", color: "#fff"}}>Remove</button>
                                            </div>
                                        </div>
                                    )
                                } else if(i.medication!='' && i.form!='' && i.strength!='' && i.quantity=='' && i.dose!='' && i.frequency!='' && i.duration!='' && i.instruction==''){
                                    return (
                                        <div key={k} style={{ width: "100%", padding: 5, backgroundColor: "#fff", borderRadius: 5, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                                <div style={{ fontSize: 18, fontWeight: 600 }}>
                                                    {i.medication}    
                                                </div>
                                                <>
                                                    <div style={{ fontSize: 12, fontWeight: 600 }}>{i.strength} {i.form}</div>
                                                    <div style={{ fontSize: 12, marginLeft: 10 }}>Sig. {i.dose}, {i.frequency}</div>
                                                </>
                                            </div>
                                            <div>
                                                <button onClick={() => onPrescriptionBtn('remove', i)}
                                                    style={{borderRadius: 5, border: "1px solid #ddd", fontWeight: 500, backgroundColor: "#cc7171", color: "#fff"}}>Remove</button>
                                            </div>
                                        </div>
                                    )
                                } else if(i.medication!='' && i.form!='' && i.strength=='' && i.quantity!='' && i.dose=='' && i.frequency=='' && i.duration!='' && i.instruction==''){
                                    return (
                                        <div key={k} style={{ width: "100%", padding: 5, backgroundColor: "#fff", borderRadius: 5, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                                <div style={{ fontSize: 18, fontWeight: 600 }}>
                                                    {i.medication}    
                                                </div>
                                                <>
                                                    <div style={{ fontSize: 12, fontWeight: 600 }}>{i.form} {i.quantity}</div>
                                                    <div style={{ fontSize: 12, marginLeft: 10 }}>Sig. {i.duration == 'Maintenance' ? '' : ' for ' + i.duration}</div>
                                                </>
                                            </div>
                                            <div>
                                                <button onClick={() => onPrescriptionBtn('remove', i)}
                                                    style={{borderRadius: 5, border: "1px solid #ddd", fontWeight: 500, backgroundColor: "#cc7171", color: "#fff"}}>Remove</button>
                                            </div>
                                        </div>
                                    )
                                } else if(i.medication!='' && i.form!='' && i.strength=='' && i.quantity!='' && i.dose=='' && i.frequency=='' && i.duration!='' && i.instruction==''){
                                    return (
                                        <div key={k} style={{ width: "100%", padding: 5, backgroundColor: "#fff", borderRadius: 5, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                                <div style={{ fontSize: 18, fontWeight: 600 }}>
                                                    {i.medication}    
                                                </div>
                                                <>
                                                    <div style={{ fontSize: 12, fontWeight: 600 }}>{i.form} {i.quantity}</div>
                                                    <div style={{ fontSize: 12, marginLeft: 10 }}>Sig. {i.duration == 'Maintenance' ? '' : ' for ' + i.duration}</div>
                                                </>
                                            </div>
                                            <div>
                                                <button onClick={() => onPrescriptionBtn('remove', i)}
                                                    style={{borderRadius: 5, border: "1px solid #ddd", fontWeight: 500, backgroundColor: "#cc7171", color: "#fff"}}>Remove</button>
                                            </div>
                                        </div>
                                    )
                                } else if(i.medication!='' && i.form!='' && i.strength=='' && i.quantity=='' && i.dose!='' && i.frequency!='' && i.duration!='' && i.instruction==''){
                                    return (
                                        <div key={k} style={{ width: "100%", padding: 5, backgroundColor: "#fff", borderRadius: 5, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                                <div style={{ fontSize: 18, fontWeight: 600 }}>
                                                    {i.medication}    
                                                </div>
                                                <>
                                                    <div style={{ fontSize: 12, fontWeight: 600 }}>{i.form}</div>
                                                    <div style={{ fontSize: 12, marginLeft: 10 }}>Sig. {i.dose}, {i.frequency} {i.duration == 'Maintenance' ? '' : ' for ' + i.duration}</div>
                                                </>
                                            </div>
                                            <div>
                                                <button onClick={() => onPrescriptionBtn('remove', i)}
                                                    style={{borderRadius: 5, border: "1px solid #ddd", fontWeight: 500, backgroundColor: "#cc7171", color: "#fff"}}>Remove</button>
                                            </div>
                                        </div>
                                    )
                                } else if(i.medication!='' && i.form!='' && i.strength!='' && i.quantity=='' && i.dose=='' && i.frequency=='' && i.duration=='' && i.instruction!=''){
                                    return (
                                        <div key={k} style={{ width: "100%", padding: 5, backgroundColor: "#fff", borderRadius: 5, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                                <div style={{ fontSize: 18, fontWeight: 600 }}>
                                                    {i.medication}    
                                                </div>
                                                <>
                                                    <div style={{ fontSize: 12, fontWeight: 600 }}>{i.strength} {i.form}</div>
                                                    <div style={{ fontSize: 12, marginLeft: 40 }}>{i.instruction}</div>
                                                </>
                                            </div>
                                            <div>
                                                <button onClick={() => onPrescriptionBtn('remove', i)}
                                                    style={{borderRadius: 5, border: "1px solid #ddd", fontWeight: 500, backgroundColor: "#cc7171", color: "#fff"}}>Remove</button>
                                            </div>
                                        </div>
                                    )
                                } else if(i.medication!='' && i.form!='' && i.strength=='' && i.quantity!='' && i.dose=='' && i.frequency=='' && i.duration=='' && i.instruction==''){
                                    return (
                                        <div key={k} style={{ width: "100%", padding: 5, backgroundColor: "#fff", borderRadius: 5, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                                <div style={{ fontSize: 18, fontWeight: 600 }}>
                                                    {i.medication}
                                                </div>
                                                <>
                                                    <div style={{ fontSize: 12, fontWeight: 600 }}>{i.form} #{i.quantity}</div>
                                                </>
                                            </div>
                                            <div>
                                                <button onClick={() => onPrescriptionBtn('remove', i)}
                                                    style={{borderRadius: 5, border: "1px solid #ddd", fontWeight: 500, backgroundColor: "#cc7171", color: "#fff"}}>Remove</button>
                                            </div>
                                        </div>
                                    )
                                } else if(i.medication!='' && i.form=='' && i.strength!='' && i.quantity!='' && i.dose=='' && i.frequency!='' && i.duration=='' && i.instruction==''){
                                    return (
                                        <div key={k} style={{ width: "100%", padding: 5, backgroundColor: "#fff", borderRadius: 5, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                                <div style={{ fontSize: 18, fontWeight: 600 }}>
                                                    {i.medication}
                                                </div>
                                                <>
                                                    <div style={{ fontSize: 12, fontWeight: 600 }}>{i.strength} #{i.quantity}</div>
                                                    <div style={{ fontSize: 12, marginLeft: 40 }}>Sig. {i.frequency}</div>
                                                </>
                                            </div>
                                            <div>
                                                <button onClick={() => onPrescriptionBtn('remove', i)}
                                                    style={{borderRadius: 5, border: "1px solid #ddd", fontWeight: 500, backgroundColor: "#cc7171", color: "#fff"}}>Remove</button>
                                            </div>
                                        </div>
                                    )
                                } else if(i.medication!='' && i.form!='' && i.strength!='' && i.quantity!='' && i.dose=='' && i.frequency=='' && i.duration=='' && i.instruction==''){
                                    return (
                                        <div key={k} style={{ width: "100%", padding: 5, backgroundColor: "#fff", borderRadius: 5, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                                <div style={{ fontSize: 18, fontWeight: 600 }}>
                                                    {i.medication}
                                                </div>
                                                <>
                                                    <div style={{ fontSize: 12, fontWeight: 600 }}>{i.strength} {i.form} #{i.quantity}</div>
                                                </>
                                            </div>
                                            <div>
                                                <button onClick={() => onPrescriptionBtn('remove', i)}
                                                    style={{borderRadius: 5, border: "1px solid #ddd", fontWeight: 500, backgroundColor: "#cc7171", color: "#fff"}}>Remove</button>
                                            </div>
                                        </div>
                                    )
                                } else if(i.medication!='' && i.form=='' && i.strength!='' && i.quantity!='' && i.dose=='' && i.frequency!='' && i.duration!='' && i.instruction==''){
                                    return (
                                        <div key={k} style={{ width: "100%", padding: 5, backgroundColor: "#fff", borderRadius: 5, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                                <div style={{ fontSize: 18, fontWeight: 600 }}>
                                                    {i.medication}
                                                </div>
                                                <>
                                                    <div style={{ fontSize: 12, fontWeight: 600 }}>{i.strength} {i.form} #{i.quantity}</div>
                                                    <div style={{ fontSize: 12, marginLeft: 10 }}>Sig. {i.dose}, {i.frequency} {i.duration == 'Maintenance' ? '' : ' for ' + i.duration}</div>
                                                </>
                                            </div>
                                            <div>
                                                <button onClick={() => onPrescriptionBtn('remove', i)}
                                                    style={{borderRadius: 5, border: "1px solid #ddd", fontWeight: 500, backgroundColor: "#cc7171", color: "#fff"}}>Remove</button>
                                            </div>
                                        </div>
                                    )
                                } else if(i.medication!='' && i.form!='' && i.strength!='' && i.quantity=='' && i.dose=='' && i.frequency!='' && i.duration!='' && i.instruction!=''){
                                    return (
                                        <div key={k} style={{ width: "100%", padding: 5, backgroundColor: "#fff", borderRadius: 5, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                                <div style={{ fontSize: 18, fontWeight: 600 }}>
                                                    {i.medication}
                                                </div>
                                                <>
                                                    <div style={{ fontSize: 12, fontWeight: 600 }}>{i.strength} {i.form}</div>
                                                    <div style={{ fontSize: 12, marginLeft: 10 }}>Sig. {i.frequency} {i.duration == 'Maintenance' ? '' : ' for ' + i.duration}</div>
                                                    <div style={{ fontSize: 12, marginLeft: 40 }}>{i.instruction}</div>
                                                </>
                                            </div>
                                            <div>
                                                <button onClick={() => onPrescriptionBtn('remove', i)}
                                                    style={{borderRadius: 5, border: "1px solid #ddd", fontWeight: 500, backgroundColor: "#cc7171", color: "#fff"}}>Remove</button>
                                            </div>
                                        </div>
                                    )
                                } else if(i.medication!='' && i.form=='' && i.strength!='' && i.quantity!='' && i.dose!='' && i.frequency=='' && i.duration=='' && i.instruction==''){
                                    return (
                                        <div key={k} style={{ width: "100%", padding: 5, backgroundColor: "#fff", borderRadius: 5, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                                <div style={{ fontSize: 18, fontWeight: 600 }}>
                                                    {i.medication}
                                                </div>
                                                <>
                                                    <div style={{ fontSize: 12, fontWeight: 600 }}>{i.strength} #{i.quantity}</div>
                                                    <div style={{ fontSize: 12, marginLeft: 10 }}>Sig. {i.dose}</div>
                                                </>
                                            </div>
                                            <div>
                                                <button onClick={() => onPrescriptionBtn('remove', i)}
                                                    style={{borderRadius: 5, border: "1px solid #ddd", fontWeight: 500, backgroundColor: "#cc7171", color: "#fff"}}>Remove</button>
                                            </div>
                                        </div>
                                    )
                                } else if(i.medication!='' && i.form=='' && i.strength=='' && i.quantity!='' && i.dose!='' && i.frequency!='' && i.duration=='' && i.instruction!=''){
                                    return (
                                        <div key={k} style={{ width: "100%", padding: 5, backgroundColor: "#fff", borderRadius: 5, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                                <div style={{ fontSize: 18, fontWeight: 600 }}>
                                                    {i.medication}
                                                </div>
                                                <>
                                                    <div style={{ fontSize: 12, fontWeight: 600 }}>#{i.quantity}</div>
                                                    <div style={{ fontSize: 12, marginLeft: 10 }}>Sig. {i.dose}, {i.frequency}</div>
                                                    <div style={{ fontSize: 12, marginLeft: 40 }}>{i.instruction}</div>
                                                </>
                                            </div>
                                            <div>
                                                <button onClick={() => onPrescriptionBtn('remove', i)}
                                                    style={{borderRadius: 5, border: "1px solid #ddd", fontWeight: 500, backgroundColor: "#cc7171", color: "#fff"}}>Remove</button>
                                            </div>
                                        </div>
                                    )
                                } else if(i.medication!='' && i.form=='' && i.strength=='' && i.quantity=='' && i.dose!='' && i.frequency!='' && i.duration=='' && i.instruction!=''){
                                    return (
                                        <div key={k} style={{ width: "100%", padding: 5, backgroundColor: "#fff", borderRadius: 5, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                                <div style={{ fontSize: 18, fontWeight: 600 }}>
                                                    {i.medication}
                                                </div>
                                                <>
                                                    <div style={{ fontSize: 12, marginLeft: 10 }}>Sig. {i.dose}</div>
                                                    <div style={{ fontSize: 12, marginLeft: 40 }}>{i.instruction}</div>
                                                </>
                                            </div>
                                            <div>
                                                <button onClick={() => onPrescriptionBtn('remove', i)}
                                                    style={{borderRadius: 5, border: "1px solid #ddd", fontWeight: 500, backgroundColor: "#cc7171", color: "#fff"}}>Remove</button>
                                            </div>
                                        </div>
                                    )
                                } else if(i.medication!='' && i.form!='' && i.strength=='' && i.quantity!='' && i.dose!='' && i.frequency=='' && i.duration=='' && i.instruction!=''){
                                    return (
                                        <div key={k} style={{ width: "100%", padding: 5, backgroundColor: "#fff", borderRadius: 5, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                                <div style={{ fontSize: 18, fontWeight: 600 }}>
                                                    {i.medication}
                                                </div>
                                                <>
                                                    <div style={{ fontSize: 12, fontWeight: 600 }}>{i.form} #{i.quantity}</div>
                                                    <div style={{ fontSize: 12, marginLeft: 10 }}>Sig. {i.dose}</div>
                                                    <div style={{ fontSize: 12, marginLeft: 40 }}>{i.instruction}</div>
                                                </>
                                            </div>
                                            <div>
                                                <button onClick={() => onPrescriptionBtn('remove', i)}
                                                    style={{borderRadius: 5, border: "1px solid #ddd", fontWeight: 500, backgroundColor: "#cc7171", color: "#fff"}}>Remove</button>
                                            </div>
                                        </div>
                                    )
                                } else if(i.medication!='' && i.form=='' && i.strength=='' && i.quantity=='' && i.dose=='' && i.frequency!='' && i.duration=='' && i.instruction!=''){
                                    return (
                                        <div key={k} style={{ width: "100%", padding: 5, backgroundColor: "#fff", borderRadius: 5, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                                <div style={{ fontSize: 18, fontWeight: 600 }}>
                                                    {i.medication}
                                                </div>
                                                <>
                                                    <div style={{ fontSize: 12, marginLeft: 10 }}>Sig. {i.frequency}</div>
                                                    <div style={{ fontSize: 12, marginLeft: 40 }}>{i.instruction}</div>
                                                </>
                                            </div>
                                            <div>
                                                <button onClick={() => onPrescriptionBtn('remove', i)}
                                                    style={{borderRadius: 5, border: "1px solid #ddd", fontWeight: 500, backgroundColor: "#cc7171", color: "#fff"}}>Remove</button>
                                            </div>
                                        </div>
                                    )
                                } else if(i.medication!='' && i.form=='' && i.strength!='' && i.quantity!='' && i.dose=='' && i.frequency!='' && i.duration=='' && i.instruction!=''){
                                    return (
                                        <div key={k} style={{ width: "100%", padding: 5, backgroundColor: "#fff", borderRadius: 5, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                                <div style={{ fontSize: 18, fontWeight: 600 }}>
                                                    {i.medication}
                                                </div>
                                                <>
                                                    <div style={{ fontSize: 12, marginLeft: 0, fontWeight: 600 }}>{i.strength} #{i.quantity}</div>
                                                    <div style={{ fontSize: 12, marginLeft: 10 }}>Sig. {i.frequency}</div>
                                                    <div style={{ fontSize: 12, marginLeft: 40 }}>{i.instruction}</div>
                                                </>
                                            </div>
                                            <div>
                                                <button onClick={() => onPrescriptionBtn('remove', i)}
                                                    style={{borderRadius: 5, border: "1px solid #ddd", fontWeight: 500, backgroundColor: "#cc7171", color: "#fff"}}>Remove</button>
                                            </div>
                                        </div>
                                    )
                                } else if(i.medication!='' && i.form!='' && i.strength!='' && i.quantity!='' && i.dose=='' && i.frequency=='' && i.duration=='' && i.instruction!=''){
                                    return (
                                        <div key={k} style={{ width: "100%", padding: 5, backgroundColor: "#fff", borderRadius: 5, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                                <div style={{ fontSize: 18, fontWeight: 600 }}>
                                                    {i.medication}
                                                </div>
                                                <>
                                                    <div style={{ fontSize: 12, marginLeft: 0, fontWeight: 600 }}>{i.form} {i.strength} #{i.quantity}</div>
                                                    <div style={{ fontSize: 12, marginLeft: 40 }}>{i.instruction}</div>
                                                </>
                                            </div>
                                            <div>
                                                <button onClick={() => onPrescriptionBtn('remove', i)}
                                                    style={{borderRadius: 5, border: "1px solid #ddd", fontWeight: 500, backgroundColor: "#cc7171", color: "#fff"}}>Remove</button>
                                            </div>
                                        </div>
                                    )
                                } else if(i.medication!='' && i.form=='' && i.strength!='' && i.quantity=='' && i.dose!='' && i.frequency!='' && i.duration!='' && i.instruction!=''){
                                    return (
                                        <div key={k} style={{ width: "100%", padding: 5, backgroundColor: "#fff", borderRadius: 5, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                                <div style={{ fontSize: 18, fontWeight: 600 }}>
                                                    {i.medication}
                                                </div>
                                                <>
                                                    <div style={{ fontSize: 12, marginLeft: 0, fontWeight: 600 }}>{i.strength}</div>
                                                    <div style={{ fontSize: 12, marginLeft: 10 }}>Sig. {i.frequency} {i.duration == 'Maintenance' ? '' : ' for ' + i.duration}</div>
                                                    <div style={{ fontSize: 12, marginLeft: 40 }}>{i.instruction}</div>
                                                </>
                                            </div>
                                            <div>
                                                <button onClick={() => onPrescriptionBtn('remove', i)}
                                                    style={{borderRadius: 5, border: "1px solid #ddd", fontWeight: 500, backgroundColor: "#cc7171", color: "#fff"}}>Remove</button>
                                            </div>
                                        </div>
                                    )
                                } else if(i.medication!='' && i.form!='' && i.strength=='' && i.quantity!='' && i.dose!='' && i.frequency!='' && i.duration!='' && i.instruction==''){
                                    return (
                                        <div key={k} style={{ width: "100%", padding: 5, backgroundColor: "#fff", borderRadius: 5, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                                <div style={{ fontSize: 18, fontWeight: 600 }}>
                                                    {i.medication}
                                                </div>
                                                <>
                                                    <div style={{ fontSize: 12, marginLeft: 0, fontWeight: 600 }}>{i.form} #{i.quantity}</div>
                                                    <div style={{ fontSize: 12, marginLeft: 10 }}>Sig. {i.frequency} {i.duration == 'Maintenance' ? '' : ' for ' + i.duration}</div>
                                                </>
                                            </div>
                                            <div>
                                                <button onClick={() => onPrescriptionBtn('remove', i)}
                                                    style={{borderRadius: 5, border: "1px solid #ddd", fontWeight: 500, backgroundColor: "#cc7171", color: "#fff"}}>Remove</button>
                                            </div>
                                        </div>
                                    )
                                }
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    );
    
}

export default CreatePrescription;
