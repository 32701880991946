import React from 'react'
//stgcinc888@gmail.com

const SUBSCRIPTION = '';

const sockeROOM = '4M09';
const socketAPI = 'http://192.168.1.11:3001';
// const DOMAIN = 'https://tubser.stgc.online/';
// const DOMAIN = 'http://192.168.1.22/tubillara_clinic/';
// const DOMAIN = 'https://api_clinic.aetosmsi.com/tubillara_clinic/'; // cloudflare port forwarding
// const DOMAIN = 'http://192.168.1.201/';
// const DOMAIN = 'http://192.168.1.245/';
// const DOMAIN = 'http://192.168.1.13/';
const DOMAIN = 'https://clinic.stgc.online/';
// const DOMAIN = 'https://tubillaraclinic.stgc.online/';

const ENV =  {
    DOMAIN,
    socketAPI,
    sockeROOM
}

export default ENV
